import React from 'react';
import PropTypes from 'prop-types';

const LoginPage = ({ children }) => {
    return (
        <div className='Login'>
            <div className='LoginBackground'></div>
            <div className='LoginContent'>
                {children}
            </div>
        </div>
    )
}

LoginPage.propTypes = {
    children: PropTypes.node.isRequired,
};

const loginLayout = (WrappedComponent) => {
    return (props) => (
        <LoginPage>
            <WrappedComponent {...props} />
        </LoginPage>
    );
};

export default loginLayout;