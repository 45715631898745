import React from 'react'
import ReactDOM from 'react-dom/client'

import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Login from './components/login/Login';
import {Course, courseLoader} from './components/course/Course';

const router = createBrowserRouter([
    { path: '/', element: <Login /> },
    { path: '/course/:id', element: <Course />, loader: courseLoader, errorElement: <div>Error loading course</div> },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
    <div className={"Assessments__wrapper"}>
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    </div>
)
