import MoodleWebService from "./MoodleWebService";

const viewCourse = async (userToken, targetCourse) => {
    // Check if userToken and targetCourse exist
    if (!userToken || !targetCourse) {
        console.error("[viewCourse Logging error]: One or more required parameters were missing");
        return;
    }

    try {
        // Call the Moodle web service to view the course
        const response = await MoodleWebService(userToken, 'core_course_view_course', {
            courseid: targetCourse
        });
        console.log('[viewCourse]', response);
        if (response && response.status === 'success') {
            console.log('Course viewed successfully');
        } else {
            console.error('Failed to view course:', response || 'Unknown error');
        }
    } catch (error) {
        console.error('Error occurred while communicating with Moodle:', error);
    }
};


const viewPage = async (userToken, targetPage) => {
    // Check if userToken exists
    if (!userToken || !targetPage) {
        console.error("[viewPage Logging error]: One of more required parameters were missing");

        return;
    }

    // Call the generic function with the appropriate wsfunction
    await MoodleWebService(userToken, 'local_mpirical_react_bridge_set_page_view', { page: targetPage, token: userToken });
};

const completeModule = async (userToken, targetModule, targetModuleName) => {
    // Check if userToken exists
    console.log("[userToken]", userToken);
    console.log("[targetModule]", targetModule);
    console.log("[targetModuleName]", targetModuleName);
    if (!userToken || !targetModule) {
        console.error("[viewPage Logging error]: One of more required parameters were missing");

        return;
    }

    // Call the generic function with the appropriate wsfunction
    await MoodleWebService(userToken, 'local_mpirical_react_bridge_set_module_complete', { moduleid: targetModule, modname: targetModuleName, token: userToken });
};

const viewModule = async (userToken, targetModule, targetModuleName) => {
    // Check if userToken exists
    if (!userToken || !targetModule) {
        console.error("[viewPage Logging error]: One of more required parameters were missing");

        return;
    }

    // Call the generic function with the appropriate wsfunction
    await MoodleWebService(userToken, 'local_mpirical_react_bridge_set_module_view', { moduleid: targetModule, modname: targetModuleName, token: userToken });
};

const setVimeoProgress = async (userToken, targetModule, targetVimeoProgress) => {
    console.log("[targetVimeoProgress]", targetVimeoProgress);
    // Check if userToken and targetModule exist
    if (!userToken || !targetModule || !(targetVimeoProgress instanceof Set)) {
        console.error("[viewPage Logging error]: One or more required parameters were missing or invalid");
        return;
    }

    try {
        // Convert targetVimeoProgress Set to an array of integers
        const progressArray = Array.from(targetVimeoProgress).map(progress => parseInt(progress, 10)); // Ensure integers

        // Call the Moodle web service to set Vimeo progress
        const response = await MoodleWebService(userToken, 'local_mpirical_react_bridge_set_vimeo_progress', {
            moduleid: targetModule,
            vimeoprogress: progressArray, // Pass the array of integers
        });

        if (response && response.status === 'success') {
            console.log('Vimeo progress saved successfully');
        } else {
            console.error('Failed to save Vimeo progress:', response || 'Unknown error');
        }
    } catch (error) {
        console.error('Error occurred while communicating with Moodle:', error);
    }
};


const getVimeoBookmark = async (userToken, targetModule, targetTime) => {
    // Check if userToken exists
    if (!userToken || !targetModule) {
        console.error("[viewPage Logging error]: One of more required parameters were missing");

        return;
    }

    // Call the generic function with the appropriate wsfunction
    await MoodleWebService(userToken, 'local_mpirical_react_bridge_get_vimeo_bookmark', { moduleid: targetModule, userprogresstime: targetTime });
};

const setVimeoBookmark = async (userToken, targetModule, targetTime) => {
    // Check if userToken exists
    if (!userToken || !targetModule) {
        console.error("[viewPage Logging error]: One of more required parameters were missing");

        return;
    }

    // Call the generic function with the appropriate wsfunction
    await MoodleWebService(userToken, 'local_mpirical_react_bridge_set_vimeo_bookmark', { moduleid: targetModule, userprogresstime: targetTime });
};


export { viewCourse, viewPage, completeModule, viewModule, setVimeoProgress, setVimeoBookmark, getVimeoBookmark };