const MoodleWebService = async (userToken, wsfunction, additionalParams) => {
    // Check if userToken exists
    if (!userToken) {
        console.error("[callMoodleWebService error]: userToken is missing");
        // You might want to handle this case accordingly, e.g., show an error message.
        return;
    }

    try {
        const endpointurl = process.env.REACT_APP_LMSURL;
        const url = `${endpointurl}/webservice/rest/server.php`;
        const moodlewsrestformat = 'json';
        const wstoken = userToken;

        // Prepare common parameters
        const commonParams = {
            wstoken: wstoken,
            wsfunction: wsfunction,
            moodlewsrestformat: moodlewsrestformat,
            ...additionalParams, // Spread additionalParams for flexibility
        };

        // Conditionally add forcenew parameter
        if (additionalParams?.forcenew !== undefined) {
            commonParams.forcenew = additionalParams.forcenew ? 1 : 0;
        }

        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(commonParams).toString(),
        };

        //console.log("[params]", params);

        const response = await fetch(url, params);
        const data = await response.json();

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        //console.log("[moodle services response]", response);

        return data;

    } catch (error) {
        //console.error("Error Logging:", error);
    }
};

export default MoodleWebService;