import { useState } from 'react';

const useUserToken = () => {
    const [userToken, setUserToken] = useState(() => {
        const storedToken = sessionStorage.getItem('userToken');
        return storedToken ? JSON.parse(storedToken) : null;
    });
    const [lmsUser, setLmsUser] = useState(() => {
        const storedUser = sessionStorage.getItem('lmsUser');
        return storedUser ? JSON.parse(storedUser) : null;
    });
    const [lmsTimeout, setLmsTimeout] = useState(() => {
        const storedTime = sessionStorage.getItem('lmsTimeout');
        return storedTime ? JSON.parse(storedTime) : null;
    });


    const setGlobalUserToken = (token) => {
        sessionStorage.setItem('userToken', JSON.stringify(token));
        setUserToken(token);
    };

    const clearGlobalUserToken = () => {
        setUserToken(null);
        setLmsUser(null);
        setLmsTimeout(null);
        sessionStorage.removeItem('userToken');
        sessionStorage.removeItem('lmsUser')
        sessionStorage.removeItem('lmsTimeout')
    };

    const setUser = async (token) => {
        try {
            const endpointurl = process.env.REACT_APP_LMSURL;
            const url = `${endpointurl}/webservice/rest/server.php`;
            const wsfunction = 'local_mpirical_react_bridge_get_user_fields';
            const moodlewsrestformat = 'json';
            const params = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    wstoken: token,
                    wsfunction: wsfunction,
                    moodlewsrestformat: moodlewsrestformat,
                    token: token
                }),
            };

            const response = await fetch(url, params);
            const data = await response.json();

            console.log("response", data);

            // Handle the response and store user information or tokens
            sessionStorage.setItem('lmsUser', JSON.stringify(data));
            setLmsUser(data);

        } catch (error) {
            // Handle authentication errors
            console.error(error);
        }
    }

    const setUserTimeout = async (token) => {
        try {
            const endpointurl = process.env.REACT_APP_LMSURL;
            const url = `${endpointurl}/webservice/rest/server.php`;
            const wsfunction = 'local_mpirical_react_bridge_get_token_expiry';
            const moodlewsrestformat = 'json';
            const params = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    wstoken: token,
                    wsfunction: wsfunction,
                    moodlewsrestformat: moodlewsrestformat,
                    token: token //needed as wstoken can't be used directly
                }),
            };

            const response = await fetch(url, params);
            const data = await response.json();

            // Handle the response and store user information or tokens
            setLmsTimeout(data.expiry);
            sessionStorage.setItem('lmsTimeout', data.expiry);

        } catch (error) {
            // Handle authentication errors
            console.error(error);
        }
    }

    const refreshUserToken = async (token) => {
        try {
            const endpointurl = process.env.REACT_APP_LMSURL;
            const url = `${endpointurl}/webservice/rest/server.php`;
            const wsfunction = 'local_mpirical_react_bridge_extend_token';
            const moodlewsrestformat = 'json';
            const params = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    wstoken: token,
                    wsfunction: wsfunction,
                    moodlewsrestformat: moodlewsrestformat,
                    token: token //needed as wstoken can't be used directly
                }),
            };

            const response = await fetch(url, params);
            const data = await response.json();

            setUserTimeout(token);

        } catch (error) {
            // Handle authentication errors
            console.error(error);
        }
    }

    return {
        userToken,
        setGlobalUserToken,
        clearGlobalUserToken,
        lmsUser,
        setUser,
        setUserTimeout,
        lmsTimeout,
        refreshUserToken
    };
};

export default useUserToken;