import React, { useEffect, useState } from 'react';
import { useParams, useLoaderData } from 'react-router-dom';
import useUserToken from '../../modules/LMS/UserToken';
import generalLayout from "../../layouts/General";
import { viewCourse } from '../../modules/LMS/Logging';
import MoodleWebService from '../../modules/LMS/MoodleWebService';
import Overview from './course_modules/Overview';
import Vimeo from './course_modules/vimeo/Vimeo';
import Quiz from './course_modules/quiz/Quiz';
import CourseFormat from './CourseFormat/CourseFormat';

export const Course = () => {
    const { courseId } = useParams();
    const { userToken } = useUserToken();
    const courseData = useLoaderData();

    useEffect(() => {
        const sendData = async () => {
            if (courseId && userToken) {
                try {
                    await viewCourse(userToken, courseId);
                    // Additional logic after viewing the course
                    console.log("logged");
                } catch (error) {
                    console.error('Error viewing course:', error);
                }
            }
        };

        sendData();
    }, []);


    return (
        true
        //<CourseFormat courseId={courseId}/>
    );
}

export async function courseLoader({ params }) {
    const courseId = parseInt(params.id) ? parseInt(params.id) : 0;

    console.log("courseId", courseId);

    return { activeCourse: courseId }
}